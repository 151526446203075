import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { obtenerProductoPorId } from "../actions/producto";
import { obtenerMarcas, obtenerMarcaPorId } from "../actions/marca";
import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import { useParams } from "react-router-dom";
// import { CantidadProducto } from "./CantidadProducto";
import { Adicionales } from "./Adicionales";
import { Presentaciones } from "./Presentaciones";
// import { Caracteristicas } from "./Caracteristicas";
import { CaracteristicasMaterialTable } from "./CaracteristicasMaterialTable";
import '../assets/css/estilo.css';

// import CategoriasCarruselSlick from "./CategoriasCarruselSlick";


// OTRA OPCION DE ZOOM https://mario-duarte.github.io/react-image-zooom/
function conParametros(Component) {
  // let params = useParams();
  // const categoriaId = params.id;
  return (props) => <Component {...props} params={useParams()} />;
}
// const navigate = useNavigate();
// function volverAtras (e) {
//     e.preventDefault();
//     // crear funcion para ver si se puede ir atras o en todo caso llevar a pag principal.
//     navigate(-1);
//   };
class ProductoFichaTecnica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productoid: "",
      empresaid: "",
      paisid: "",
      monedaSigno: "",
      imagenActual: 0,
      estaactivo: " ",
      categoriaNombre: "",
    };
    // if(props.producto && props.producto.productoimagen){
    //   for (let i = 0; i < this.props.producto.productoimagen.length; i++) {
    //     this["myRef" + this.props.producto.productoimagen[i]] = React.createRef();
    //   }
    // }
  }
  componentDidMount() {
  
    window.scrollTo(0, 0);
    if (this.props.params && this.props.params.id) {
      this.props.obtenerProductoPorId(this.props.params.id);
      this.setState({
        productoid: this.props.params.id,
      });
    }
    // ...
   
  }
  componentWillReceiveProps({ empresa }) {
    
    if (empresa && empresa.sucursal) {
      this.setState({
        empresaid: empresa.id,
        paisid: empresa.paisid,
        monedaSigno: (empresa.sucursal && empresa.sucursal[0]) ? empresa.sucursal[0].moneda.signo: '',
        tienda: empresa.tienda,
        colorprimario:empresa.colorprimario, //se agregó para probar cambiar color dinamicamente
        whatsapp: empresa.whatsapp,
      });
    }
  }


  // funcion para crear el link de whastapp para cada con texto dinamico por producto
  createTextLink = ()=> {
    // const nombrePro = this.props.producto.nombre && this.props.producto.nombre.split(" ").join("+");
    // const precioPro = this.props.producto.precio && this.props.producto.precio.toString();
    const nombrePro = this.props.producto.nombre;
    const precioPro = this.props.producto.precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const moneda = this.state.monedaSigno;
    const textoLink = `Buenas! me interesa comprar el siguiente producto:\r\n${nombrePro} en ${moneda} ${precioPro}`;
    const encodedTextoLink = encodeURIComponent(textoLink);
    const urlBase = `https://wa.me/${this.state.whatsapp}?text=${encodedTextoLink}`;
    
    return urlBase;
       
  }


  render() {
   
    const irAImagen = (imagenIndex) => {
      this.setState({ imagenActual: imagenIndex }, () =>
        document.getElementById("irASlide").click()
      );
    };
    const agrandarImagen = (e) => {
      document
        .getElementById("imagen" + this.state.imagenActual)
        .nextSibling.click();
    };
    const { producto } = this.props;

 

    let imagenes = [];
    if (producto && producto.productoimagen) {
      imagenes = producto.productoimagen;
    }

    
  
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      // const {
      //   carouselState: { currentSlide },
      // } = rest;
      return (
        <div className="d-none">
          {/* <button
            className={currentSlide === 0 ? "disable" : ""}
            onClick={() => previous()}
          />
          <button onClick={() => next()} /> */}
          <button
            id="irASlide"
            onClick={() => goToSlide(this.state.imagenActual)}
          ></button>
        </div>
      );
    };
    return (
     
      <section className='container-fluid container-xxl seccion-promociones'>
        <div className='row mt-3'>
          <div className='col-12'>
            <div className='card card-navegacion'>
              <div className='card-body d-flex align-items-center'>
                <Link
                  to={{
                    pathname: `/productocategoria/${producto.categoriaid}`,
                  }}
                  className='link'
                >
                <svg className="icon-arrow-left-navegacion" xmlns="http://www.w3.org/2000/svg" width="26.39" height="26.39" viewBox="0 0 26.39 26.39"><path d="M13.758,26.953a13.2,13.2,0,1,1,13.2-13.2A13.193,13.193,0,0,1,13.758,26.953Zm6.172-15.536H13.758V7.644a.639.639,0,0,0-1.091-.452L6.585,13.305a.633.633,0,0,0,0,.9l6.081,6.113a.638.638,0,0,0,1.091-.452V16.1h6.172a.64.64,0,0,0,.638-.638V12.055A.64.64,0,0,0,19.929,11.417Z" transform="translate(-0.563 -0.563)"/></svg>

                </Link>
                <span className='d-none ms-2 nav-barra d-xl-flex'>
                  {/* <a href="promociones.html" className="link txt-oscuro">
                    Promociones /&nbsp;
                  </a> */}
                  <Link
                    to={{
                      pathname: `/productocategoria/${producto.categoriaid}`,
                    }}
                    className='link txt-oscuro'
                  >
                    {producto &&
                      producto.categoria &&
                      producto.categoria.nombre + ' / '}
                  </Link>
                  <a href='/#' className='link txt-gris-claro'>
                    {<>&nbsp;</>}
                    {producto.nombre}
                  </a>
                </span>
                <div className='ms-2 nav-barra d-xl-none'>
                  <span className='txt-oscuro'>{producto.nombre}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          {/* <div className="col-xl-9">  */}
          <div className='col-xl-12'>
            {/* /PRODUCTO */}
            <div className='card card-producto-detalle px-3 py-4'>
              {/* <img
                src={require("../assets/img/oferta.png")}
                className="card-producto-oferta"
                width="80px"
                alt=""
              /> */}
              <div className='row row-producto-detalle justify-content-center'>
                <div className='col-xl-1 col-md-1 col-sm-1 img-miniatura'>
                  {producto.productoimagen &&
                    producto.productoimagen.map(
                      (productoImagen, productoImagenIndex) => {
                        return (
                          <div
                            // className="listado-img border-activo"
                            className={
                              productoImagenIndex === this.state.imagenActual
                                ? 'listado-img  border-activo'
                                : 'listado-img '
                            }
                            // className={`listado-img ` + this.state.estaactivo}
                            key={productoImagenIndex}
                            // onClick={() => this.irAImagen(productoImagenIndex)}
                            onClick={() => irAImagen(productoImagenIndex)}
                          >
                            <img
                              src={
                                
                                process.env.REACT_APP_URL_IMAGENES +
                                this.state.paisid +
                                '/' +
                                this.state.empresaid +
                                '/productoimagen/' +
                                producto.id +
                                '/' +
                                productoImagen.imagen
                              }
                              alt={producto.nombre}
                              width='100%'
                          
                            />
                          </div>
                        );
                      }
                    )}
                </div>
                <div
                  className='col-xl-8 col-md-11 col-sm-11 d-flex justify-content-center align-items-center'
                  style={{
                    position: 'relative',
                  }}
                >
                  <Carousel
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside={true}
                    focusOnSelect={true}
                    shouldResetAutoplay={false}
                    // beforeChange={(nextSlide, { currentSlide, onMove }) => {
                    //   currentSlide.preventDefault();

                    // }}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay={false}
                    centerMode={false}
                    className=''
                    containerClass='container mx-auto p-0'
                    partialVisible={false}
                    dotListClass=''
                    swipeable={false}
                    draggable={false}
                    itemClass='d-flex justify-content-center align-items-center container'
                    keyBoardControl
                    minimumTouchDrag={80}
                    showDots={false}
                    // renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 1,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 1,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 1,
                      },
                    }}
                    sliderClass=''
                    slidesToSlide={1}
                    // swipeable
                  >
                    {imagenes.length > 0 ? (
                      imagenes.map((imagen, imagenIndex) => {
                        return (
                          <div key={imagenIndex}>
                            <Zoom>
                              <img
                                className='cardImgPrincipalProducto'
                                id={'imagen' + imagenIndex}
                                ref={this['myRef' + imagenIndex]}
                                src={
                                  process.env.REACT_APP_URL_IMAGENES +
                                  this.state.paisid +
                                  '/' +
                                  this.state.empresaid +
                                  '/productoimagen/' +
                                  producto.id +
                                  '/' +
                                  imagen.imagen
                                }
                                alt=''
                                width='100%'
                              />
                            </Zoom>
                          </div>
                        );
                      })
                    ) : (
                      <Zoom>
                        <img
                          className='cardImgPrincipalProducto'
                          src={require('../assets/img/productoimagendefault.jpg')}
                          alt=''
                          width='100%'
                        />
                      </Zoom>
                    )}
                    
                  </Carousel>
                </div>
                {/* <div className='col-2 col-md-1 col-xl-1 d-flex align-items-end'>
                   <button
                    className="btn-celeste icon-zoom"
                    onClick={(e) => agrandarImagen(e)}
                  >
                    <i className="fas fa-search-plus" />
                  </button> 
                </div> */}
                {/* <div className='col-12 mt-3 col-sm-10 col-md-5 mt-xl-2'> */}
                  {/* <div className="row text-center"> */}
                  <div className='col-xl-3 col-md-12 col-sm-12 mt-3 mt-xl-2 px-md-3 cardProductoInfo'>
                  
                  <p className='titulo txt-celeste d-xl-block'>
                  {producto.marca && producto.marca.nombre}
                  </p>
                    <h2 className='titulo txt-celeste d-none d-xl-block'>
                      {producto.nombre}
                    </h2>
                    <p className='txt-oscuro p-0 card-producto-detalle-descripcion mt-xl-2'>
                      {producto.resumen}
                      </p>
                      <p className='txt-oscuro p-0 card-producto-detalle-descripcion mt-xl-2'>
                      {producto.descripcion}
                    </p>
                    <h2 className='precio-principal-producto txt-celeste text-end align-self-end d-md-block'>
                      {this.state.monedaSigno}{' '}
                      {producto.precio &&
                        producto.precio
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      {/* <span className="precio-normal">Gs 120.000</span> */}
                    </h2>
                    {/* </div> */}
                  {/* </div> */}

                  {/* <div className="row justify-content-center mt-4">
                    <div className="col-6 d-flex pe-0 align-items-center col-xl-8 col-xxl-6">
                      <img
                        src={require("../assets/img/icon-minus-celeste.svg")}
                        className="btn-minus-plus"
                        alt=""
                      />
                      <input
                        type="number"
                        className="form-input mx-2 text-center"
                        defaultValue={1}
                      />
                      <img
                        src={require("../assets/img/icon-plus-celeste.svg")}
                        className="btn-minus-plus"
                        alt=""
                      />
                    </div>
                    <div className="col-6 d-flex justify-content-evenly col-xl-12 mt-xl-4">
                      <button className="btn-celeste btn-xl-celeste">
                        <i className="fas fa-shopping-cart me-xxl-1" />
                        Agregar
                      </button>
                      <button className="btn-celeste btn-xl-celeste d-xl-none">
                        <i className="fas fa-share" />
                      </button>
                    </div> 
                  </div> */}
                </div>
              </div>
            </div>
            {/* PRODUCTO / */}
          </div>
        </div>

        {/* Presentaciones de producto */}

        {producto.presentacion && producto.presentacion.length === 0 ? (
          ''
        ) : (
          <Presentaciones producto={producto} tienda={this.state.tienda} />
        )}

        {/* Presentaciones de producto */}



        {/* Adicionales de producto */}

        {producto.productoadicional ? (
          <Adicionales producto={producto} tienda={this.state.tienda} />
        ) : (
          ''
        )}

        {/* Adicionales de producto */}



        {/* Caracteristicas */}

              {/* <Caracteristicas /> se comenta componente sin materialUI, falta actualizar sus props si se quiere usar */}

          {producto.productocaracteristica && producto.productocaracteristica.length === 0 ? (
          
          ''
        ) : (

          <CaracteristicasMaterialTable producto={producto} /> 
          
          ) }

        {/* Caracteristicas */}



        {/* Confirmar pedido */}

        {/* ##### 
        
        ## SE COMENTA BLOQUE PORQUE ESTE SERA UN TEMPLATE CON VISTA DE PRODUCTO CON FICHA TECNICA ##
        
         <div className="row mt-3">
          <div className="col-xl-12">
            <div className="card card-producto-detalle px-2 py-3">
              <div className="row justify-content-end row-no-gutters row-cols-3 align-items-center confirmar-pedido">
                {this.state.tienda === "n" ?
                <>
                <div className="txt-celeste text-end col-xl-3 col-lg-2 col-md- col-sm col-xs mt-2 mt-xxl-3">
                  
                </div>
                </>
                :
                <>
                <div className="txt-celeste text-end col-xl-3 col-lg-2 col-md col-sm col-xs mt-2 mt-xxl-3">
                  <p>Sub total</p>
                </div>
                </>
                }
                
                <h2 className="precio-principal-producto txt-celeste text-end text-nowrap col-xl-3 col-lg-3 col-md col-sm col-xs">
                  {this.state.monedaSigno}{" "}
                  {producto.precio &&
                    producto.precio
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </h2> */}

                {/* <button className="btn-celeste col-xl-3 col-lg-3 col-md col-sm col-xs btn-xl-celeste mx-4">
                  <i className="fas fa-shopping-cart me-xxl-1" />
                                  Agregar
                </button> */}
                
                {/* <button className='btn-celeste col-xl-3 col-lg-3 col-md col-sm col-xs btn-xl-celeste mx-3'
                        
                        onClick={() =>window.open(this.createTextLink())}>
                
                  <i className='fab fa-whatsapp me-sm-3' />
                  
                  Pedir por Whatsapp
                
                </button>

                 <button className="btn-celeste btn-xl-celeste d-xl-none">
                  <i className="fas fa-share" />
                </button> 

              </div>
            </div>
          </div>
        </div> */}

        {/* Confirmar pedido */}

        {/* <CategoriasCarruselSlick /> */}

      </section>
    );
  }
}
// export default Producto;
const mapStateToProps = (state) => {
 
  return {
    producto: state.producto,
    empresa: state.empresa,
  };
};
// export default Categorias;
export default connect(mapStateToProps, {
  obtenerProductoPorId,
  obtenerMarcaPorId,
  
})(conParametros(ProductoFichaTecnica));
// export default connect(mapStateToProps, {
//   obtenerProductoPorId,
// })(Producto, conParametros);

//export default conParametros(Producto);
