import React, { useState } from 'react'

export const CantidadProducto = () => {

    const [ counter, setCounter] = useState(0);

    const handleAdd = () => {
     
     setCounter(counter + 1);
    };

    const handleDecr = () => {
      
       if (counter === 0 ) return;

       setCounter(counter - 1);
     };


  return (
    
    
                <div className="row justify-content-center mt-1 mx-1">
                    {/* <div className="row-cols-3 row-no-gutters col-4 pe-0 align-items-center justify-content-center col-md-offset-4 col-xl-6 col-xxl-4 input-group"> */}
                    <div className="row-cols-3 row-no-gutters justify-content-center align-items-center" style={{display:"inline-flex"}}>
                      
                    <svg onClick={ handleDecr } className="btn-minus-plus input-group-btn" xmlns="http://www.w3.org/2000/svg" width="16.68" height="16.68" viewBox="0 0 16.68 16.68"><path d="M8.9.563a8.34,8.34,0,1,0,8.34,8.34A8.339,8.339,0,0,0,8.9.563ZM4.463,10.248a.4.4,0,0,1-.4-.4V7.961a.4.4,0,0,1,.4-.4h8.878a.4.4,0,0,1,.4.4V9.844a.4.4,0,0,1-.4.4Z" transform="translate(-0.563 -0.563)"></path></svg>
                        
                    
                      <input
                        style={{width:"3em"}}
                        type="number"
                        className="form-input mx-2 text-center"
                        // defaultValue={1}
                        onChange={(event) => setCounter(event.target.value)}
                        value={counter}
                      />
                      <svg onClick={ handleAdd } className="btn-minus-plus input-group-btn" xmlns="http://www.w3.org/2000/svg" width="16.68" height="16.68" viewBox="0 0 16.676 16.676"><path d="M8.9.563A8.338,8.338,0,1,0,17.239,8.9,8.337,8.337,0,0,0,8.9.563Zm4.841,9.279a.4.4,0,0,1-.4.4H10.245v3.093a.4.4,0,0,1-.4.4H7.959a.4.4,0,0,1-.4-.4V10.245H4.463a.4.4,0,0,1-.4-.4V7.959a.4.4,0,0,1,.4-.4H7.556V4.463a.4.4,0,0,1,.4-.4H9.842a.4.4,0,0,1,.4.4V7.556h3.093a.4.4,0,0,1,.4.4Z" transform="translate(-0.563 -0.563)"/></svg>
                     
                    </div>
                </div>


  )
}
