import * as React from 'react';

import { obtenerProductosPorCategoria } from '../actions/producto';
// import { obtenerCategoriaPorId } from "../actions/categoria";
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import CardProductoMaterial from './CardProductoMaterial';

const mapDispatchToProps = (dispatch) => {
  return {
    obtenerProductosPorCategoria: (id) => {
      dispatch(obtenerProductosPorCategoria(id));
    },
    // obtenerCategoriaPorId: (id) => {
    //   dispatch(obtenerCategoriaPorId(id));
    // },
  };
};
const mapStateToProps = (state) => {
  return {
    productos: state.producto,
    empresa: state.empresa,
    categoria: state.producto && state.producto[0] ? state.producto[0].categoria : state.categorias,
  };
};

const ProductosCategoria = (props) => {
  // const [categoria, setCategoria] = React.useState("");
  let { categoria } = props;
  //   const [producto, setProducto] = React.useState("");
  let color = props.colorEmpresa;
  let nombreCategoria = '';
  let params = useParams();
  const categoriaId = params.id;
  //   const categoriaId = props.location.id;
  //   const { producto, cargarProducto } = dispatch(obtenerProductosPorCategoria("6QtbpzVxQJL91RYMcpLyYqas7lSkHKgu"));
  //   const producto = props.obtenerProductosPorCategoria(categoriaId);
  //   const dispatch = useDispatch();
  const { obtenerProductosPorCategoria } = props;
  const { productos } = props || {};
  React.useEffect(() => {
    window.scrollTo(0, 0);
    obtenerProductosPorCategoria(categoriaId);
    // obtenerCategoriaPorId(categoriaId);
  }, [categoriaId, obtenerProductosPorCategoria]);

  if (props.categoria && productos && productos[0]) {
    categoria = productos.find(
      (producto) => producto.categoria.id === categoriaId
    );

    //ricardo urbieta 21-10-2022 se agrega validacion para traer color de empresa si categoria no tiene color
      if(props.categoria.color){

        color = props.categoria.color;
      }
      else {
        color = props.colorEmpresa;
      }
      //ricardo urbieta 21-10-2022 se agrega validacion para omitir descripcion de categoria si no existe
      if(props.categoria.descripcion){

        nombreCategoria =
        props.categoria.nombre + ' - ' + props.categoria.descripcion;
      }
      else {
        nombreCategoria =
      props.categoria.nombre;
      }
    
  }
  const navigate = useNavigate();
  const volverAtras = (e) => {
    e.preventDefault();
    // crear funcion para ver si se puede ir atras o en todo caso llevar a pag principal.
    // navigate(-1);
    navigate('/');
  };
  return (
    <section className='container-fluid container-xxl seccion-promociones'>
      <div className='row mt-3'>
        <div className='col-12'>
          <div
            className='card card-navegacion'
            style={{ backgroundColor: color }}
          >
            <div className='card-body d-flex align-items-center'>
              <a href='/' className='link' onClick={(e) => volverAtras(e)}>
                <h5 className='txt-blanco titulo m-0'>
                  <img
                    src={require('../assets/img/icon-arrow-left-navegacion-blanco.svg')}
                    alt='Volver'
                  />
                  <span className='ms-2 nav-barra'>
                    <span className='txt-blanco'>{nombreCategoria}</span>
                  </span>
                </h5>
                {/* </Link> */}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-3'>
        {/* <!-- FILTRO --> */}
        {/*
        <div className="col-xl-3 d-none d-xl-flex">
          <div id="panel-filtro-desktop" className="card card-filtro mt-2">
            <div className="card-header">
              <h5 className="titulo txt-oscuro">
                <img
                  src={require("../assets/img/icon-options-celeste.svg")}
                  className="me-2"
                  width="20px"
                  alt="Opciones"
                />
                Filtrar Por
              </h5>
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="titulo txt-oscuro">Precio Gs</h6>
                    <i className="fas fa-chevron-up txt-celeste" />
                  </div>
                  <div>
                    <div className="container mt-2 text-center">
                      <div className="price-slider">
                        <div className="d-flex justify-content-between">
                          <input
                            className="rango-precio txt-oscuro"
                            type="number"
                            defaultValue={0}
                            min={0}
                            max={120000}
                          />
                          <input
                            className="rango-precio txt-oscuro text-end"
                            type="number"
                            defaultValue={120000}
                            min={0}
                            max={120000}
                          />
                        </div>
                        <input
                          defaultValue={0}
                          min={0}
                          max={120000}
                          step={500}
                          type="range"
                        />
                        <input
                          defaultValue={120000}
                          min={0}
                          max={120000}
                          step={500}
                          type="range"
                        />
                      </div>
                      <button className="btn-celeste-filtro mt-3">
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="titulo txt-oscuro">Marcas</h6>
                    <i className="fas fa-chevron-up txt-celeste" />
                  </div>
                  <ul className="filtro-listado mt-2">
                    <li>
                      <a href="/#" className="link txt-oscuro">
                        Marca 1
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="link txt-oscuro">
                        Marca 2
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="link txt-oscuro">
                        Marca 3
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="link txt-oscuro">
                        Marca 4
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}

        <div className='col-12'>
          <div className='row justify-content-center'>
            {props.productos &&
              props.productos.length > 0 &&
              props.productos.map((producto, index) => {
                return (
                  <div
                    className='col-12 col-md-6 col-lg-4 col-xl-3 p-xl-2 mt-3 mt-xl-0'
                    key={index}
                  >
                    <CardProductoMaterial producto={producto} empresa={props.empresa} />
                  </div>
                );
              })}
            {!props.productos ? (
              <p className='text-center'>Sin registros</p>
            ) : props.productos.length === 0 ? (
              <div className='d-flex justify-content-center mt-xl-5'>
                <div
                  className='spinner-grow txt-celeste'
                  style={{ width: '4rem', height: '4rem' }}
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
// const mapDispatchToProps = (state) => {
//   return {
//     producto: state.producto,
//     empresa: state.empresa,
//   };
// };

// const mapStateToProps = (state) => {
//   return {
//     producto: state.producto,
//     empresa: state.empresa,
//   };
// };
// export default ProductosCategoria;
// export default connect(mapStateToProps, mapDispatchToProps, {
//   obtenerProductosPorCategoria,
// })(ProductosCategoria);
export default connect(mapStateToProps, mapDispatchToProps)(ProductosCategoria);
 