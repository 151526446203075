import React from 'react';
// import Megadigital from "../assets/img/megadigitallog.png";
import { connect } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { obtenerCategorias } from '../actions/categoria';
import { obtenerMarcas } from '../actions/marca';
// import { Link } from "react-router-dom";

// class Nav extends React.Component {
const Nav = (props) => {
  const [muestraFiltros, setmuestraFiltros] = React.useState('d-none');
  const [errorMensaje, setErrorMensaje] = React.useState('');

  const [categoriaSel, setcategoriaSel] = React.useState('');
  const [marcaSel, setMarcaSel] = React.useState('');
  const [busqueda, setBusqueda] = React.useState('');

  const { obtenerMarcas } = props;
  React.useEffect(() => {
    obtenerMarcas();
    // obtenerCategoriaPorId(categoriaId);
  }, [obtenerMarcas]);
  // let state = {
  //   muestraFiltros: "d-none",
  // };
  // componentWillReceiveProps(nextProps) {
  //   // this.setState({
  //   //   empresaid: nextProps.empresa[0].id,
  //   //   paisid: nextProps.empresa[0].paisid,
  //   //   categoriaid: null,
  //   // });
  // }
  // componentDidMount() {
  //   this.props.obtenerCategorias();
  // }
  const muestraFiltro = () => {
    if (muestraFiltros === 'd-none') {
      setmuestraFiltros('');
    } else {
      setmuestraFiltros('d-none');
    }
  };
  const seleccionaCategoria = (index, id) => {
    setcategoriaSel(id);
  };
  const seleccionaMarca = (id) => {
    setMarcaSel(id);
  };
  const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (pathname, params) =>
      navigate({ pathname, search: `?${createSearchParams(params)}` });
  };
  const navigateSearch = useNavigateSearch();
  const buscar = (e) => {
    e.preventDefault();

    if (busqueda.trim() !== '' && busqueda.length > 1) {
      let parametros = {};
      parametros['b'] = busqueda;
      if (categoriaSel && categoriaSel.length > 1) {
        parametros['categoriaid'] = categoriaSel;
      }
      if (marcaSel && marcaSel.length > 1) {
        parametros['marcaid'] = marcaSel;
      }
      //   navigate({
      //   pathname: '/busqueda/',
      //   search: '?sort=date&order=newest',
      // });
      if (muestraFiltros === '') {
        muestraFiltro();
      }
      // navigateSearch(`/busqueda/${busqueda}`, parametros);
      navigateSearch(`/busqueda/`, parametros);
      // navigateSearch(`/busqueda/${busqueda}`, {
      //   categoriaid: categoriaSel,
      //   marcaid: marcaSel,
      // });
    } else {
      setErrorMensaje(
        'Debe ingresar al menos dos palabras para realizar la busqueda'
      );
    }
  };
  const reiniciar = (e) => {
    e.preventDefault();
    setMarcaSel(null);
    setcategoriaSel(null);
    setBusqueda(null);
  };

  const isTienda = false;

  function diaSemana(param) {
    switch (param) {
      case 0:
        return 'Lunes';
      case 1:
        return 'Martes';
      case 2:
        return 'Miércoles';
      case 3:
        return 'Jueves';
      case 4:
        return 'Viernes';
      case 5:
        return 'Sábado';
      default:
        return ' ';
    }
  }

  return (
    <header>
      {/* NAVBAR-TOP */}
      <nav className='navbar navbar-light navbar-lg-celeste'>
        <div className='container-fluid container-xxl justify-content-center'>
          <div className='row w-100 align-items-center'>
            <div className='col-1 col-xl-3 d-flex justify-content-start p-0 align-items-center'>
              <input type='checkbox' id='menu-navbar' hidden />
              <button className='btn-card-white' type='button'>
                <label
                  className='fas fa-bars txt-celeste navbar-label'
                  htmlFor='menu-navbar'
                ></label>
              </button>
              <h4  className='titulo ms-4 d-none d-xl-block'>
                <a id="nombreEmpresaNav" className='txt-oscuro link txt-lg-blanco' href='/#'>
                  {props.alias}
                </a>
              </h4>
              <div className='navbar-items-container'>
                <ul className='navbar-nav me-auto mb-2 mb-lg-0 w-100'>
                  <li className='nav-item'>
                    <a className='nav-link' aria-current='page' href='/#'>
                      <i className='fas fa-home txt-celeste' />
                      Inicio
                    </a>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link'>
                      <i className='fas fa-info-circle txt-celeste' />
                      Información
                    </div>
                    <div className='nav-ubicaciones'>
                      <p className='nav-ubicaciones-title'>
                        {props.empresa.nombre}
                      </p>
                      <ul className='footer-listado mt-2'>
                        {props.empresa.biografia && (
                          <li>
                            <div className='row w-100'>
                              <div className='col-12 text-start'>
                                {props.empresa.biografia}
                              </div>
                            </div>
                          </li>
                        )}
                        {props.empresa.correo && (
                          <li>
                            <div className='row w-100'>
                              <div className='col-12 text-start'>
                                {props.empresa.correo}
                              </div>
                            </div>
                          </li>
                        )}
                        {props.empresa.urlfacebook && (
                          <li>
                            <a
                              href={props.empresa.urlfacebook}
                              className='link txt-oscuro'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='row w-100'>
                                <div className='col-1 text-start'>
                                  <i className='fab fa-facebook-f txt-celeste nav-rs-i' />
                                </div>
                                <div className='col-11 txt-oscuro text-start'>
                                  Facebook
                                </div>
                              </div>
                            </a>
                          </li>
                        )}
                        {props.empresa.urlinstagram && (
                          <li>
                            <a
                              href={props.empresa.urlinstagram}
                              className='link txt-oscuro'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='row w-100'>
                                <div className='col-1 text-start'>
                                  <i className='fab fa-instagram txt-celeste nav-rs-i' />
                                </div>
                                <div className='col-11 txt-oscuro text-start'>
                                  Instagram
                                </div>
                              </div>
                            </a>
                          </li>
                        )}
                        {props.empresa.urltwitter && (
                          <li>
                            <a
                              href={props.empresa.urltwitter}
                              className='link txt-oscuro'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='row w-100'>
                                <div className='col-1 text-start'>
                                  <i className='fab fa-twitter txt-celeste nav-rs-i' />
                                </div>
                                <div className='col-11 txt-oscuro text-start'>
                                  Twitter
                                </div>
                              </div>
                            </a>
                          </li>
                        )}
                        {props.empresa.whatsapp && (
                          <li>
                            <a
                              href={
                                'https://api.whatsapp.com/send?phone=' +
                                props.empresa.whatsapp
                              }
                              className='link txt-oscuro'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='row w-100'>
                                <div className='col-1 text-start'>
                                  <i className='fab fa-whatsapp txt-celeste nav-rs-i' />
                                </div>
                                <div className='col-11 txt-oscuro text-start'>
                                  Whatsapp
                                </div>
                              </div>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='nav-link col-12'>
                      <i className='fas fa-map-marker-alt txt-celeste' />
                      Ubicaciones
                    </div>
                    <div className='nav-ubicaciones'>
                      <p className='nav-ubicaciones-title'>
                        {props.sucursal > 2 ? 'Sucursales' : 'Casa Central'}
                      </p>
                      <ul className='footer-listado mt-2'>
                        {props.empresa &&
                          props.empresa.sucursal &&
                          props.empresa.sucursal.length > 0 &&
                          props.empresa.sucursal.map(
                            (sucursal, indexSucursal) => (
                              <li key={indexSucursal}>
                                <a
                                  href={
                                    sucursal.latitud.length > 0
                                      ? `https://www.google.com/maps/search/?api=1&query=${sucursal.latitud},${sucursal.longitud}`
                                      : '/'
                                  }
                                  className='link txt-oscuro'
                                >
                                  {sucursal.direccion}
                                  {/* {sucursal.alias} - {sucursal.direccion} */}
                                </a>
                              </li>
                            )
                          )}
                      </ul>
                      {props.empresa.sucursal &&
                        props.empresa.sucursal.map((sucursal, indexSucursal) =>
                          sucursal.sucursalhorario.map(
                            (sucursalHorario, indexSucursalHorario) => (
                              <div
                                className='row w-100'
                                key={indexSucursalHorario}
                              >
                                <div className='col-3 txt-celeste text-start'>
                                  <b>
                                    <small>
                                      {' '}
                                      {diaSemana(sucursalHorario.dia)}{' '}
                                    </small>
                                    {/* <small> {(() => {
                                switch (sucursalHorario.dia) {
                                  case 0:
                                    return()
                                    break;
                                
                                  default:
                                    break;
                                }
                              })()}</small> */}
                                  </b>
                                </div>
                                <div className='col-9 txt-oscuro text-start'>
                                  <b>
                                    {' '}
                                    <small>
                                      {' '}
                                      {sucursalHorario.horarioapertura.substring(
                                        0,
                                        5
                                      )}{' '}
                                      -{' '}
                                      {sucursalHorario.horariocierre.substring(
                                        0,
                                        5
                                      )}
                                    </small>
                                  </b>
                                </div>
                              </div>
                            )
                          )
                        )}
                    </div>
                  </li>
                </ul>
                <div className='desarrollado-por text-center'>
                  <small className='text-muted d-block'>Desarrollado por</small>
                  <a
                    href={'https://www.megadigital.com.py/'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={require('../assets/img/megadigitallog.png')}
                      className='py-2'
                      width='35px'
                      alt='Megadigital'
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className='d-none d-xl-flex col-lg-9'>
              {/* SECTION BUSCADOR */}
              <section className='container-fluid'>
                <form action='/#'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      // name="busqueda"
                      className='form-input me-3'
                      placeholder='Buscar'
                      onChange={(e) => setBusqueda(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          buscar(e);
                        }
                      }}
                    />
                    <button
                      className='btn-card-white me-2'
                      type='button'
                      onClick={(e) => buscar(e)}
                    >
                      <i className='fas fa-search txt-celeste' />
                    </button>
                  </div>
                </form>
              </section>
              {/* / SECTION BUSCADOR */}
            </div>
            <div className='col-10 text-center d-xl-none'>
              <h3 className='titulo'>
                <a className='txt-oscuro link txt-lg-blanco' href='/#' >
                  {props.alias}
                </a>
              </h3>
            </div>
            <div
              className={`${
                isTienda ? 'd-flex' : 'd-none'
              } col-1 col-xl-3 justify-content-end p-0`}
            >
              <a
                className='btn-card-white me-2 d-none d-xl-flex'
                aria-current='page'
                href='mipedido.html'
              >
                <img
                  src={require('../assets/img/Icon-bag-celeste.svg')}
                  width='20px'
                  alt='Mis Pedidos'
                />
              </a>
              <a
                className='btn-card-white me-2 d-none d-xl-flex'
                aria-current='page'
                href='/#'
              >
                <img
                  src={require('../assets/img/icon-gift-celeste.svg')}
                  width='20px'
                  alt='Cupones'
                />
              </a>
              <a
                className='btn-card-white me-2 d-none d-xl-flex'
                aria-current='page'
                href='miperfil.html'
              >
                <img
                  src={require('../assets/img/icon-bell-celeste.svg')}
                  width='20px'
                  alt='Notificaciones'
                />
              </a>
              <a
                className='btn-card-white'
                aria-current='page'
                href='miperfil.html'
              >
                <i className='fas fa-user txt-celeste' />
              </a>
            </div>
          </div>
        </div>
      </nav>
      {/* / NAVBAR-TOP */}
      {/* NAVBAR-BOTTOM */}
      <nav className='navbar navbar-bottom-blanco d-none'>
        <div className='container-fluid d-flex justify-content-space-between align-items-center'>
          <button className='btn-transparent'>
            <label
              className='fas fa-bars txt-celeste navbar-label'
              htmlFor='menu-navbar'
            ></label>
          </button>
          <button className='btn-transparent'>
            <img
              src={require('../assets/img/icon-search-celeste.svg')}
              width='26px'
              alt='Buscar'
            />
          </button>
          <button className='btn-transparent'>
            {/* ESTE BOTÓN ES SOLO PARA NO ROMPER LA FILA */}
            <span className='px-4' />
          </button>
          <a href='mipedido.html' className='btn-card-circulo-celeste'>
            <img
              src={require('../assets/img/icon-bag-blanco.svg')}
              width='26px'
              alt='Mi Pedido'
            />
          </a>
          <button className='btn-transparent'>
            <img
              src={require('../assets/img/icon-gift-celeste.svg')}
              width='26px'
              alt='Cupones'
            />
          </button>
          <button className='btn-transparent'>
            <img
              src={require('../assets/img/icon-bell-celeste.svg')}
              width='26px'
              alt='Notificaciones'
            />
          </button>
        </div>
      </nav>
      {/* / NAVBAR-BOTTOM */}
      {/* SECTION BUSCADOR */}
      <section className='container-fluid seccion-buscador d-xl-none'>
        <form action='/#'>
          <div className='d-flex'>
            <input
              type='text'
              // name="buscar"
              className='form-input me-3'
              placeholder='Buscar'
              onChange={(e) => setBusqueda(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  buscar(e);
                }
              }}
            />
            <button
              className='btn-card-white me-2'
              type='button'
              onClick={(e) => buscar(e)}
            >
              <i className='fas fa-search txt-celeste' />
            </button>
            <button
              id='btn-filtro-movil'
              className='btn-card-white'
              type='button'
              onClick={() => muestraFiltro()}
            >
              <svg
                  className="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"     viewBox="0 0 31.5 31.5"
                >
                  <g transform="translate(-2.25 -2.25)">
                    <path d="M2.25,27H21.375v2.25H2.25Z"  />
                    <path d="M28.125,27H33.75v2.25H28.125Z"  />
                    <path
                      d="M27,31.465a2.268,2.268,0,0,1-2.25,2.285h0a2.268,2.268,0,0,1-2.25-2.285v-6.68A2.268,2.268,0,0,1,24.75,22.5h0A2.268,2.268,0,0,1,27,24.785Z"
                      
                    />
                    <path d="M2.25,16.875H7.875v2.25H2.25Z"  />
                    <path
                      d="M14.625,16.875H33.75v2.25H14.625Z"
                      
                    />
                    <path
                      d="M13.5,21.34a2.268,2.268,0,0,1-2.25,2.285h0A2.268,2.268,0,0,1,9,21.34V14.66a2.268,2.268,0,0,1,2.25-2.285h0A2.268,2.268,0,0,1,13.5,14.66Z"
                      
                    />
                    <path d="M2.25,6.75H21.375V9H2.25Z"  />
                    <path d="M28.125,6.75H33.75V9H28.125Z"  />
                    <path
                      d="M27,11.215A2.268,2.268,0,0,1,24.75,13.5h0a2.268,2.268,0,0,1-2.25-2.285V4.535A2.268,2.268,0,0,1,24.75,2.25h0A2.268,2.268,0,0,1,27,4.535Z"
                      
                    />
                  </g>
                </svg>
            </button>
          </div>
          <div className='invalid-feedback'>{errorMensaje}</div>
          <div
            id='panel-filtro-movil'
            className={'card card-filtro mt-2 ' + muestraFiltros}
          >
            <div className='card-header'>
              <h5 className='titulo txt-oscuro'>
                {/* <img
                  src={require('../assets/img/icon-options-celeste.svg')}
                  className='me-2'
                  width='20px'
                  alt='Opciones'
                /> */}
                <svg
                  className="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"     viewBox="0 0 31.5 31.5"
                ><g transform="translate(-2.25 -2.25)">
                    <path d="M2.25,27H21.375v2.25H2.25Z"  />
                    <path d="M28.125,27H33.75v2.25H28.125Z"  />
                    <path
                      d="M27,31.465a2.268,2.268,0,0,1-2.25,2.285h0a2.268,2.268,0,0,1-2.25-2.285v-6.68A2.268,2.268,0,0,1,24.75,22.5h0A2.268,2.268,0,0,1,27,24.785Z"
                      
                    />
                    <path d="M2.25,16.875H7.875v2.25H2.25Z"  />
                    <path
                      d="M14.625,16.875H33.75v2.25H14.625Z"
                      
                    />
                    <path
                      d="M13.5,21.34a2.268,2.268,0,0,1-2.25,2.285h0A2.268,2.268,0,0,1,9,21.34V14.66a2.268,2.268,0,0,1,2.25-2.285h0A2.268,2.268,0,0,1,13.5,14.66Z"
                      
                    />
                    <path d="M2.25,6.75H21.375V9H2.25Z"  />
                    <path d="M28.125,6.75H33.75V9H28.125Z"  />
                    <path
                      d="M27,11.215A2.268,2.268,0,0,1,24.75,13.5h0a2.268,2.268,0,0,1-2.25-2.285V4.535A2.268,2.268,0,0,1,24.75,2.25h0A2.268,2.268,0,0,1,27,4.535Z"
                      
                    />
                  </g>
                </svg>
                Filtrar Por
              </h5>
            </div>
            <div className='card-body pt-0'>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='titulo txt-oscuro'>Categorías</h6>
                    {/* <i className='fas fa-chevron-up txt-celeste' /> */}
                  </div>
                  <ul className='filtro-listado mt-2'>
                    {props.categorias &&
                      props.categorias.map((categoria, index) => (
                        <li key={index}>
                          <div
                            className={
                              categoriaSel === categoria.id
                                ? 'link txt-oscuro seleccionado'
                                : 'link txt-oscuro '
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              seleccionaCategoria(index, categoria.id)
                            }
                          >
                            {categoria.nombre}
                          </div>
                          {/* <Link
                            to={`/productocategoria/${categoria.id}`}
                            className="link txt-oscuro"
                          >
                            {categoria.nombre}
                          </Link> */}
                        </li>
                      ))}

                    {/* <li>
                        <a href="/#" className="link txt-oscuro">
                          Carnicería
                        </a>
                      </li>
                      <li>
                        <a href="/#" className="link txt-oscuro">
                          Limpieza
                        </a>
                      </li>
                      <li>
                        <a href="/#" className="link txt-oscuro">
                          Verdulería
                        </a>
                      </li> */}
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  {/* <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='titulo txt-oscuro'>Precio Gs</h6>
                    <i className='fas fa-chevron-up txt-celeste' />
                  </div>
                  <div>
                    <div className='container mt-2 text-center'>
                      <div className='price-slider'>
                        <div className='d-flex justify-content-between'>
                          <input
                            className='rango-precio txt-oscuro'
                            type='number'
                            defaultValue={0}
                            min={0}
                            max={120000}
                          />
                          <input
                            className='rango-precio txt-oscuro text-end'
                            type='number'
                            defaultValue={120000}
                            min={0}
                            max={120000}
                          />
                        </div>
                        <input
                          defaultValue={0}
                          min={0}
                          max={120000}
                          step={500}
                          type='range'
                        />
                        <input
                          defaultValue={120000}
                          min={0}
                          max={120000}
                          step={500}
                          type='range'
                        />
                      </div> */}
                      {/* <button className='btn-celeste-filtro mt-5'>
                        Aceptar
                      </button> */}
                    {/* </div>
                  </div> */}
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='titulo txt-oscuro mt-5'>Marcas</h6>
                    {/* <i className='fas fa-chevron-up txt-celeste' /> */}
                  </div>
                  <ul className='filtro-listado mt-2'>
                    {props.marcas &&
                      props.marcas.map((marca, marcaIndex) => (
                        <li key={marcaIndex}>
                          <div
                            className={
                              marcaSel === marca.id
                                ? 'link txt-oscuro seleccionado'
                                : 'link txt-oscuro '
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => seleccionaMarca(marca.id)}
                          >
                            {marca.nombre}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 d-flex justify-content-end'>
                  <button
                    className='btn-celeste-filtro justify-content-end'
                    onClick={(e) => reiniciar(e)}
                  >
                    Reiniciar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {/* / SECTION BUSCADOR */}
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    categorias: state.categorias,
    marcas: state.marca,
    empresa: state.empresa,
  };
};

export default connect(mapStateToProps, {
  obtenerCategorias,
  obtenerMarcas,
})(Nav);
