import * as React from "react";
import { CantidadProducto } from "./CantidadProducto";
import "bootstrap/dist/css/bootstrap.min.css";


export const Adicionales = ({producto, tienda}) => {


  
  return (
    <div>
      {producto.productoadicional &&
        producto.productoadicional.map((extra, index) => (
          <div className="row mt-2">
            <div className="col-xl-12">
              <div className="card card-producto-detalle px-2 py-3">
                <h3 key={index} className="aditional-title h-20">
                  {extra.adicional.descripcion}
                </h3>

                <ul className="aditionals-list">
                  {extra.adicional.adicionaldetalle.map((item) => (
                    <li
                      key={item.id}
                      className="aditionals-item row row-cols-3 justify-content-around align-items-center my-3"
                    >
                                         
                      {tienda === "n"  ? (
                        <>

                        <div className="col-md-6 col-sm-4 col-xs-4  marginright-mobile">
                         {item.descripcion}
                        </div>

                        <div className="col-md-5 col-sm-7 col-xs-6 text-end">
                        {" "}
                        Gs. {item.importeadicional}
                        </div>

                        <div className="col-md-1 col-sm-1 col-xs-1"></div>

                        </>

                      ) : (

                        <>

                        <div className="col-md-6 col-sm-4 col-xs-4">
                        <input type="radio" /> {item.descripcion}
                        </div>

                        <div className="col-md-2 col-sm-3 col-xs-3 text-end">
                        {" "}
                        Gs. {item.importeadicional}
                      </div>
                        <div className="col-md-4 col-sm-5 col-xs-5">
                          {" "}
                          <CantidadProducto />{" "}
                        </div>

                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
