import { combineReducers } from "redux";
import categorias from "./categorias";
import empresa from "./empresa";
import marca from "./marca";
import producto from "./producto";
export default combineReducers({
  categorias,
  empresa,
  producto,
  marca,
});
