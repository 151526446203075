import * as React from 'react';
import { CardActionArea, CardActions, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function CardProductoMaterial({ producto, empresa }) {
 
  
  return (

   
   <Card elevation={2} style={{height:"24em",display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <CardActionArea style={{textDecoration:"none"}}>
          <Link
            to={{
              pathname: `/producto/${producto.id}`,
            }}
            style={{color:'var(--color-celeste)',textDecoration: "none", fontSize:'1em'}}
            className='txt-celeste'          >
        <CardMedia
          component="img"
          height="140"
          image={
                producto.productoimagen[0] && empresa
                  ? process.env.REACT_APP_URL_IMAGENES +
                    empresa.paisid +
                    '/' +
                    empresa.id +
                    '/productoimagen/' +
                    producto.id +
                    '/' +
                    producto.productoimagen[0].imagen
                  : require('../assets/img/productoimagendefault.jpg')
              }
          alt={producto.nombre}
        />
       {/* <CardContent style={{ height: '100%' ,marginBottom: 'calc(var(--bs-gutter-x) * 0.5)',display: 'flex',
  flexDirection: 'column', justifyContent: 'space-between'}}> */}
        <CardContent style={{textDecoration:"none"}} >

        {empresa && empresa.id === "qOF2fhXI8IGbanbm3ylZYRCe4eHN5tlj" ?
        (
          <Typography gutterBottom variant="h6" component="div" style={{textDecoration:"none"}}>
          {producto.nombre}
          </Typography>
        )
        :
        (
          <Typography gutterBottom variant="h6" component="div" style={{textDecoration:"none", wordBreak: "break-word", fontSize: "15px"}}>
          {producto.nombre}
          </Typography>
        )

        }
          
          {/* <Typography gutterBottom variant="h5" component="div">
          
          <p className='txt-celeste mt-2'>
                  {empresa.sucursal[0].moneda.signo}{' '}
                  {producto.precio
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          </p>
          </Typography> */}
          {/* //variant="body1" color="text.secondary" */}
          <Typography style={{textDecoration:"none"}}> 
          {empresa.sucursal && empresa.sucursal[0] && empresa.sucursal[0].moneda.signo}{' '}
                  {producto.precio
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          </Typography>
        </CardContent>
        </Link>
      </CardActionArea>
      <CardActions className='de-flex justify-content-center'>
        {/* <Button size="small" color="primary">
          Share
        </Button> */}
        <div className='col-6 col-xl-12 text-center d-flex mt-xl-3 justify-content-center'>
        <Link
                to={{
                  pathname: `/producto/${producto.id}`,
                }}
              >
                <button className='btn-celeste' align='center' style={{padding: "0.5rem 5rem", fontSize: "17px", fontWeight: "700"}}>
                  {/*<i className='fas fa-shopping-cart' />*/}
                  Ver
                </button>
              </Link>
              </div>
      </CardActions>
    </Card>
    
  );
}
