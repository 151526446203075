import http from "../http-common";
class EmpresaDatosServicio {
  listadoEmpresa() {
    return http.get("/empresa/listar");
  }
  buscarEmpresaPorId(id) {
    // return http.get(`/tutorials/${id}`);
    return http.get("/empresa/buscar/id", id);
  }
  buscarEmpresaPorSubdominio() {
    return http.get("/empresa/subdominio");
  }
  //   create(data) {
  //     return http.post("/tutorials", data);
  //   }
  //   update(id, data) {
  //     return http.put(`/tutorials/${id}`, data);
  //   }
  //   delete(id) {
  //     return http.delete(`/tutorials/${id}`);
  //   }
  //   deleteAll() {
  //     return http.delete(`/tutorials`);
  //   }
  //   findByTitle(title) {
  //     return http.get(`/tutorials?title=${title}`);
  //   }
}
export default new EmpresaDatosServicio();
