import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { obtenerProductosPorCategoria } from "../actions/producto";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

function conParametros(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}
class Productos extends React.Component {
  constructor(props) {
    super(props);
    console.log("sprop  ", props.location);

    this.state = {
      empresaid: "",
      paisid: "",
      productoid: "",
      categoriaid: "",
      categoriaColor: "",
      monedaDescripcion: "",
      monedaSigno: "",
    };
  }
  componentDidMount() {
    console.log("funca:",this.props.params);;

    // const query = new URLSearchParams(location.search);
    // console.log("STACK ES: ", query );
    console.log("parent:", this.props.parentState);
    console.log("location:", this.props.parentState);
    console.log("location en produjcto", this.props.location);

    console.log("props antes son", this.props);
    this.props.obtenerProductosPorCategoria("6QtbpzVxQJL91RYMcpLyYqas7lSkHKgu");
    // console.log("asd1111111",this.props.match.params.categoriaid);
    // this.setState({
    //   categoriaColor: this.props.producto[0].categoria.color,
    // });
  }
  componentWillReceiveProps(nextProps) {
    console.log("states:", this.state);
    console.log("NEXT SON productos", nextProps);
    // console.log("NEXT SON productos2", nextProps.producto[0].categoria);
    this.setState({
      empresaid: nextProps.empresa.id,
      paisid: nextProps.empresa.paisid,
      categoriaid: null,
      monedaSigno: nextProps.empresa.sucursal[0].moneda.signo,
      // categoriaColor: nextProps.producto[0],
    });
  }
  render() {
    const { producto } = this.props;
    console.log("productos:", producto);
    // const { classes } = this.props;
    return (
      
      <div className="row">
        <div className="mt-3">
          <div className="col-12">
            <div
              className="card card-navegacion"
              style={{ backgroundColor: this.state.categoriaColor }}
            >
              <div className="card-body">
                <a href="index.html" className="link">
                  <h5 className="txt-blanco titulo m-0">
                    <img
                      src={require("../assets/img/icon-arrow-left-navegacion-blanco.svg")}
                      alt="Volver"
                    />
                    <span className="ms-2">Carnicería</span>
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {producto &&
            producto.map((producto, index) => (
              <div className="card card-producto mt-3" key={index}>
                <div className="row h-100">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    {/* <Link to={"/producto/" + producto.id} state={{productoid: producto.id}}> */}
                    <Link
                      to={{
                        pathname: `/producto/${producto.id}`,
                        state: "hollaa???????",
                      }}
                    >
                      {/* <Link
                        to={`/producto/${producto.id}`}
                        state={{ productoid: producto.id }} // <-- state prop
                        key={producto.id}
                        > */}
                      <img
                        className="img-producto"
                        src={
                          process.env.REACT_APP_URL_IMAGENES +
                          this.state.paisid +
                          "/" +
                          this.state.empresaid +
                          "/productoimagen/" +
                          producto.productoimagen[0].id +
                          "/" +
                          producto.productoimagen[0].imagen
                        }
                        alt={producto.nombre}
                      />
                    </Link>
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <div className="col-12">
                        {/* <Link
                          to="/producto"
                          state={{ productoid: producto.id }}
                          className="link"
                        > */}
                        <Link
                          to={{
                            pathname: "/producto",
                            state: { productoid: producto.id },
                          }}
                        >
                          <h5 className="txt-celeste mt-2">
                            {this.state.monedaSigno}{" "}
                            {producto.precio
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </h5>
                          <p className="txt-oscuro p-0 card-producto-nombre">
                            {producto.nombre}
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 d-flex pe-0 align-items-center">
                        <p className="txt-oscuro unidadmedida">
                          {producto.unidadmedida.descripcion}
                        </p>
                        <img
                          src={require("../assets/img/icon-minus-celeste.svg")}
                          className="btn-minus-plus"
                          width="50px"
                          alt=""
                        />
                        <input
                          type="number"
                          className="form-input-minus-plus mx-2 text-center"
                          // defaultValue={producto.unidadmedida.descripcion ? "Unidad"}
                          defaultValue={1}
                        />
                        <img
                          src={require("../assets/img/icon-plus-celeste.svg")}
                          className="btn-minus-plus"
                          width="50px"
                          alt=""
                        />
                      </div>
                      <div className="col-6 text-center d-flex">
                        <button className="btn-celeste">
                          <i className="fas fa-shopping-cart" />
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("en map es:", state);
  return {
    producto: state.producto,
    empresa: state.empresa,
  };
};
// export default Categorias;
export default connect(mapStateToProps, {
  obtenerProductosPorCategoria,
})(Productos, conParametros);
