import { OBTENER_CATEGORIAS, OBTENER_CATEGORIA_POR_ID } from "./types";
import CategoriaDatosServicio from "../services/categoria.service.js";
export const obtenerCategorias = () => async (dispatch) => {
  try {
    const res = await CategoriaDatosServicio.listadoCategoria();
    dispatch({
      type: OBTENER_CATEGORIAS,
      payload: res.data.categoria,
    });
  } catch (err) {
    console.log(err);
  }
};
export const obtenerCategoriaPorId = (categoriaid) => async (dispatch) => {
  try {
    const res = await CategoriaDatosServicio.buscarCategoriaPorId(categoriaid);
    console.log("res en categoria por id ", res);
    dispatch({
      type: OBTENER_CATEGORIA_POR_ID,
      payload: res.data.categoria,
    });
  } catch (err) {
    console.log(err);
  }
};
