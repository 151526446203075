import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { obtenerCategorias } from '../actions/categoria';
import { obtenerMarcas } from '../actions/marca';
import { obtenerProductosBusquedaAvanzada } from '../actions/producto';

import { useNavigate, useSearchParams } from 'react-router-dom';
import CardProductoMaterial from './CardProductoMaterial';

// import "../assets/js/main";
// import '../assets/css/estilo.css'
// class Busqueda extends React.Component {
const Busqueda = (props) => {
  const [searchParams] = useSearchParams();

  const [categoriaSel, setcategoriaSel] = React.useState('');
  const [marcaSel, setMarcaSel] = React.useState('');
  const [precioMinSel, setprecioMinSel] = React.useState(0);
  const [precioMaxSel, setprecioMaxSel] = React.useState(0);

  // const [busquedaNombre, setBusquedaNombre] = React.useState("");
  const [busquedaNombre, setBusquedaNombre] = React.useState('');
  const { obtenerMarcas, obtenerCategorias, obtenerProductosBusquedaAvanzada } =
    props;

  React.useEffect(() => {
    obtenerMarcas();
    obtenerCategorias();
    let datos = {};
    if (searchParams.get('b')) {
      setBusquedaNombre(searchParams.get('b'));
      datos['nombre'] = searchParams.get('b');
    }
    if (searchParams.get('categoriaid')) {
      setcategoriaSel(searchParams.get('categoriaid'));
      datos['categoriaid'] = searchParams.get('categoriaid');
    }
    if (searchParams.get('marcaid')) {
      setMarcaSel(searchParams.get('marcaid'));
      datos['marcaid'] = searchParams.get('marcaid');
    }
    if (searchParams.get('preciomin')) {
      setprecioMinSel(searchParams.get('preciomin'));
      datos['preciomin'] = searchParams.get('preciomin');
    }
    if (searchParams.get('preciomax')) {
      setprecioMaxSel(searchParams.get('preciomax'));
      datos['preciomax'] = searchParams.get('preciomax');
    }
    obtenerProductosBusquedaAvanzada(datos);
    // obtenerCategoriaPorId(categoriaId);
  }, [
    obtenerMarcas,
    obtenerCategorias,
    searchParams,
    obtenerProductosBusquedaAvanzada,
  ]);

  // let state = {
  //   class: "d-none",
  // };
  // const handleClick = () => {
  //   if (state.class === "d-none") {
  //     // setState({ class: "" });
  //     state.class({ class: "" });
  //   } else {
  //     state.class({ class: "d-none" });
  //     // setState({ class: "d-none" });
  //   }
  // };
  const useNavigateSearch = () => {
    const navigate = useNavigate();
    let buscando = busquedaNombre;
    if (buscando.length > 0) {
      buscando = busquedaNombre;
    } else {
      buscando = searchParams.get('b');
    }
    let queryBusqueda = {};
    queryBusqueda['b'] = buscando;
    if (categoriaSel !== '') {
      queryBusqueda['categoriaid'] = categoriaSel;
    }
    if (marcaSel.length > 0) {
      queryBusqueda['marcaid'] = marcaSel;
    }
    queryBusqueda = new URLSearchParams(queryBusqueda).toString();
    return (pathname) => navigate({ pathname, search: `?${queryBusqueda}` });
  };
  const navigateSearch = useNavigateSearch();

  // const seleccionaCategoria = (index, id) => {
  //   setcategoriaSel({ id }, () => navigateSearch(`/busqueda/`));
  //   // navigateSearch(`/busqueda/`);
  // };
  // const seleccionaMarca = (id) => {
  //   setMarcaSel({ id }, () => navigateSearch(`/busqueda/`));
  // };
  // const seleccionaPrecioMin = (precio) => {
  //   setprecioMinSel({ precio }, () => navigateSearch(`/busqueda/`));
  // };
  // const seleccionaPrecioMax = (precio) => {
  //   setprecioMaxSel({ precio }, () => navigateSearch(`/busqueda/`));
  // };
  const seleccionaCategoria = (id) => {
    setcategoriaSel(id);
    // navigateSearch(`/busqueda/`);
  };
  const seleccionaMarca = (id) => {
    setMarcaSel(id);
    // navigateSearch(`/busqueda/`);
  };
  const seleccionaPrecioMin = (precio) => {
    setprecioMinSel(precio);
  };
  const seleccionaPrecioMax = (precio) => {
    setprecioMaxSel(precio);
  };
  React.useEffect(() => {
    if (categoriaSel) {
      navigateSearch(`/busqueda/`);
    }
    if (marcaSel) {
      navigateSearch(`/busqueda/`);
    }
    if (precioMinSel || precioMaxSel) {
      navigateSearch(`/busqueda/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaSel, marcaSel, precioMinSel, precioMaxSel]);
  // uReact.useEffect(() => {
  //   setBusquedaNombre(params.nombre);
  // }, [busqedaNombre, params]);

  return (
    <section className="container-fluid container-xxl seccion-promociones">
      <div className="row mt-3">
        <div className="col-xl-3 d-none d-xl-block">
          <div id="panel-filtro-desktop" className="card card-filtro">
            <div className="card-header">
              <h5 className="titulo txt-oscuro">
                
                <svg
                  className="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"     viewBox="0 0 31.5 31.5"
                >
                  <g transform="translate(-2.25 -2.25)">
                    <path d="M2.25,27H21.375v2.25H2.25Z"  />
                    <path d="M28.125,27H33.75v2.25H28.125Z"  />
                    <path
                      d="M27,31.465a2.268,2.268,0,0,1-2.25,2.285h0a2.268,2.268,0,0,1-2.25-2.285v-6.68A2.268,2.268,0,0,1,24.75,22.5h0A2.268,2.268,0,0,1,27,24.785Z"
                      
                    />
                    <path d="M2.25,16.875H7.875v2.25H2.25Z"  />
                    <path
                      d="M14.625,16.875H33.75v2.25H14.625Z"
                      
                    />
                    <path
                      d="M13.5,21.34a2.268,2.268,0,0,1-2.25,2.285h0A2.268,2.268,0,0,1,9,21.34V14.66a2.268,2.268,0,0,1,2.25-2.285h0A2.268,2.268,0,0,1,13.5,14.66Z"
                      
                    />
                    <path d="M2.25,6.75H21.375V9H2.25Z"  />
                    <path d="M28.125,6.75H33.75V9H28.125Z"  />
                    <path
                      d="M27,11.215A2.268,2.268,0,0,1,24.75,13.5h0a2.268,2.268,0,0,1-2.25-2.285V4.535A2.268,2.268,0,0,1,24.75,2.25h0A2.268,2.268,0,0,1,27,4.535Z"
                      
                    />
                  </g>
                </svg>
                Filtrar Por
              </h5>
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="titulo txt-oscuro">Categorías</h6>
                    {/* <i className="fas fa-chevron-up txt-celeste" /> */}
                  </div>
                  <ul className="filtro-listado mt-2">
                    {props.categorias &&
                      props.categorias.map((categoria, index) => (
                        <li key={index}>
                          <div
                            className={
                              categoriaSel === categoria.id
                                ? "link txt-oscuro seleccionado"
                                : "link txt-oscuro "
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => seleccionaCategoria(categoria.id)}
                          >
                            {categoria.nombre}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {/* <div className="d-flex justify-content-between align-items-center">
                    <h6 className="titulo txt-oscuro">Precio Gs</h6>
                    <i className="fas fa-chevron-up txt-celeste" />
                  </div>
                  <div>
                    <div className="container mt-2 text-center">
                      <div className="price-slider">
                        <div className="d-flex justify-content-between">
                          <input
                            className="rango-precio txt-oscuro"
                            type="number"
                            // defaultValue={0}
                            min={0}
                            max={1000000}
                            value={precioMinSel}
                            onChange={(e) =>
                              seleccionaPrecioMin(e.target.value)
                            }
                          />
                          <input
                            className="rango-precio txt-oscuro text-end"
                            type="number"
                            // defaultValue={120000}
                            min={0}
                            max={1000000}
                            value={precioMaxSel}
                            onChange={(e) =>
                              seleccionaPrecioMax(e.target.value)
                            }
                          />
                        </div>
                        <input
                          defaultValue={0}
                          min={0}
                          max={120000}
                          step={500}
                          type="range"
                        />
                        <input
                          defaultValue={120000}
                          min={0}
                          max={120000}
                          step={500}
                          type="range"
                        />
                      </div> */}
                      {/* <button className="btn-celeste-filtro mt-3">
                        Aceptar
                      </button> */}
                    {/* </div>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="titulo txt-oscuro mt-5">Marcas</h6>
                    {/* <i className="fas fa-chevron-up txt-celeste" /> */}
                  </div>
                  <ul className="filtro-listado mt-2">
                    {props.marcas &&
                      props.marcas.map((marca, marcaIndex) => (
                        <li key={marcaIndex}>
                          <div
                            className={
                              marcaSel === marca.id
                                ? "link txt-oscuro seleccionado"
                                : "link txt-oscuro "
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => seleccionaMarca(marca.id)}
                          >
                            {marca.nombre}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <h2>Resultados de búsqueda para: '{busquedaNombre}'</h2>
          <div className="col-12">
            <div className="row justify-content-center">
              {props.productos &&
                props.productos.length > 0 &&
                props.productos.map((producto, index) => {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4 col-xl-3 p-xl-2 mt-3 mt-xl-0"
                      key={index}
                    >
                      <CardProductoMaterial
                        producto={producto}
                        empresa={props.empresa}
                      />
                    </div>
                  );
                })}
              {!props.productos ? (
                <p className="text-center">Sin registros</p>
              ) : props.productos.length === 0 ? (
                <div className="d-flex justify-content-center mt-xl-5">
                  <div
                    className="spinner-grow txt-celeste"
                    style={{ width: "4rem", height: "4rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
// export default Busqueda;
const mapStateToProps = (state) => {
  return {
    categorias: state.categorias,
    marcas: state.marca,
    empresa: state.empresa,
    productos: state.producto,
  };
};
export default connect(mapStateToProps, {
  obtenerCategorias,
  obtenerMarcas,
  obtenerProductosBusquedaAvanzada,
})(Busqueda);
