import { OBTENER_EMPRESAPORSUBDOMINIO } from "./types";
import EmpresaDatosServicio from "../services/empresa.service";
export const obtenerEmpresaPorSubdominio = () => async (dispatch) => {
  try {
    const res = await EmpresaDatosServicio.buscarEmpresaPorSubdominio();
    dispatch({
      type: OBTENER_EMPRESAPORSUBDOMINIO,
      payload: res.data.empresa,
    });
  } catch (err) {
    console.log("EN ACTION EMPRESA ERROR CATCH");
    // dispatch({ type: "OBTENER_EMPRESA_ERROR", err });
    dispatch({ 
      type: "OBTENER_EMPRESA_ERROR", 
      payload: "0",
     });
    console.log(err);
  }
};
