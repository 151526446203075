import http from "../http-common";
class MarcaDatosServicio {
  listadoMarca() {
    return http.get("/marca/listar");
  }
    buscarMarcaPorId(id) {
      return http.get(`/marca/buscar/id/${id}`);
    }
    // buscarMarcaPorNombre(nombre) {
    //   return http.get(`/marca/buscar/nombre/${nombre}`);
    // }
    
}
export default new MarcaDatosServicio();
