import { OBTENER_MARCAS,
  OBTENER_MARCA_POR_ID, } from "../actions/types";
const initialState = [];
function marcaReducer(marcas = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OBTENER_MARCAS:
      return payload;

    case OBTENER_MARCA_POR_ID:
    return payload;
    default:
      return marcas;
  }
}
export default marcaReducer;
