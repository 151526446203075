import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import '../assets/css/estilo.css'
import { connect } from 'react-redux';
import { obtenerCategorias } from '../actions/categoria';
import { Link } from 'react-router-dom';
// import { Navigate } from "react-router-dom";
// import {Link, useNavigate} from 'react-router-dom';
// import { obtenerEmpresaPorSubdominio } from "../actions/empresa";

class Categorias extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      categorias: '',
      empresaid: '',
      paisid: '',
    };

    // if (!repos || repos.length === 0) return <p>No repos, sorry</p>;
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.props.obtenerCategorias();
    }
  }
  componentWillReceiveProps({ empresa }) {
    if (empresa) {
      this.setState({
        empresaid: empresa.id,
        paisid: empresa.paisid,
        categoriaid: null,
        colorprimario:empresa.colorprimario,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  // this.setState({
  //   empresaid: nextProps.empresa.id,
  //   paisid: this.props.empresa.paisid,
  // })
  // this.setState({
  //   aboutMe: nextProps.aboutMe,
  // });
  // }
  // componentDidMount() {
  //   const apiUrl = 'http://megadigital.localhost:8000/api/categoria/listar';
  //   fetch(apiUrl)
  //     .then((response) => response.json())
  // }

  render() {
    // const navigate = useNavigate();

    // const muestraProductos = (categoriaid) => {
    //   // e.preventDefault();
    //   this.setState({ categoriaid: categoriaid });
    //   // navigate("/productos", { state: { categoriaid: categoriaid } });
    // };
    const { categorias } = this.props;
    if (categorias.length > 0) {
      return (
        <section className='container-fluid container-xxl mt-xl-5'>
          <div className='row mt-3 mb-3'>
            <div className='col-12'>
              <h5 className='txt-oscuro titulo titulo-xl'>Categorías</h5>
            </div>
          </div>
          <div className='row mt-2 mt-xl-0 justify-content-center'>
            {categorias &&
              categorias.map((categoria, index) => (
                <div
                  className='col-12 d-flex align-items-center flex-column mb-4 col-sm-6 mb-sm-6 col-md-6 mb-md-6 col-lg-4 mb-lg-4 col-xl-4 mb-xl-4'
                  key={index}
                >
                  <Link
                    to={`/productocategoria/${categoria.id}`}
                    state={{ categoriaid: categoria.id, producto: '' }}
                    className='link w-100'
                  >
                    <div className='card card-categoria'>
                      <div className='card-body'>
                      {categoria.imagen.length > 0 && this.state.empresaid ?
                        <img
                          src={
                            process.env.REACT_APP_URL_IMAGENES +
                            this.state.paisid +
                            '/' +
                            this.state.empresaid +
                            '/categoria/' +
                            categoria.id +
                            '/' +
                            categoria.imagen
                          }
                          alt={categoria.nombre}
                        />
                          :
                        <img
                          className='card-img-principal-producto'
                          src={require('../assets/img/productoimagendefault.jpg')}
                          alt=''
                          width='100%'
                        />
                      }
                      </div>
                      <div
                        className='card-footer'
                        style={{ backgroundColor: categoria.color || this.state.colorprimario }} //ricardo Urbieta 21-10-2022 trae color de empresa si no tiene color la categoria
                      >
                        {categoria.nombre}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </section>
      );
    } else {
      return (
        <div className='d-flex justify-content-center mt-xl-5'>
          <div
            className='spinner-grow txt-celeste'
            style={{ width: '4rem', height: '4rem' }}
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    categorias: state.categorias,
    empresa: state.empresa,
  };
};
// export default Categorias;
export default connect(mapStateToProps, {
  obtenerCategorias,
})(Categorias);
