import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// import '../assets/css/estilo.css'
class Promocion extends React.Component {
  render() {
    let hasPromotions = false;
    if (hasPromotions) {
      return (
        <section className='container-fluid container-xxl seccion-promociones'>
          <div className='row mt-4 mb-3'>
            <div className='col-12'>
              <h5 className='txt-oscuro titulo titulo-xl'>Promociones</h5>
            </div>
          </div>
          <div className='row mt-2 '>
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=''
              containerClass='container mx-auto p-0'
              partialVisible={false}
              dotListClass=''
              draggable
              focusOnSelect={false}
              infinite
              itemClass='container'
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                },
              }}
              showDots
              sliderClass=''
              slidesToSlide={1}
              swipeable
            >
              <div>
                <a href='promociones.html'>
                  <img
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    src={require('../assets/img/img-promocion.jpg')}
                    className='d-block w-100'
                    alt='Promoción'
                  />
                </a>
              </div>
              <div>
                <a href='promociones.html'>
                  <img
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    src={require('../assets/img/img-supersale.jpg')}
                    className='d-block w-100'
                    alt='Promoción'
                  />
                </a>
              </div>
              <div>
                <a href='promociones.html'>
                  <img
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    src={require('../assets/img/img-supersale.jpg')}
                    className='img-fluid'
                    alt='Promoción'
                  />
                </a>
              </div>
            </Carousel>
            {/* <div className="col-12">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={0}
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={1}
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <a href="promociones.html">
                    <img
                      src={require("../assets/img/img-promocion.jpg")}
                      className="d-block w-100"
                      alt="Promoción"
                    />
                  </a>
                </div>
                <div className="carousel-item">
                  <a href="promociones.html">
                    <img
                      src={require("../assets/img/img-supersale.jpg")}
                      className="d-block w-100"
                      alt="Promoción"
                    />
                  </a>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div> */}
          </div>
        </section>
      );
    } else {
      return (
        <section className='container-fluid container-xxl seccion-nd'></section>
      );
    }
  }
}
export default Promocion;
