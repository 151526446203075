import http from "../http-common";
class ProductoDatosServicio {
  listadoProductos(destacado) {
    return http.get("/producto/listar", {
      params: {
        destacado: destacado
      }
    });
  }
  buscarProductoPorId(id) {
    // return http.get(`/tutorials/${id}`);
    return http.get(`/producto/buscar/id/${id}`);
    // return http.get("/producto/buscar/id/", id);
  }
  buscarProductoPorNombre(nombre) {
    return http.get("/producto/buscar/nombre", nombre);
  }
  buscarProductoPorCategoria(categoriaid) {
    return http.get(`/producto/buscar/categoria/${categoriaid}`);
    // return http.post("/producto/buscar/categoria", {
    //   categoriaid: categoriaid,
    // });
  }
  buscarProductoAvanzado(datos) {
    return http.get(`/producto/buscar/avanzado`, {
      params: datos,
    });
    // return http.post("/producto/buscar/categoria", {
    //   categoriaid: categoriaid,
    // });
  }
  //   create(data) {
  //     return http.post("/tutorials", data);
  //   }
  //   update(id, data) {
  //     return http.put(`/tutorials/${id}`, data);
  //   }
  //   delete(id) {
  //     return http.delete(`/tutorials/${id}`);
  //   }
  //   deleteAll() {
  //     return http.delete(`/tutorials`);
  //   }
  //   findByTitle(title) {
  //     return http.get(`/tutorials?title=${title}`);
  //   }
}
export default new ProductoDatosServicio();
