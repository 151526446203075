import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

// class Error extends React.Component {
function Error() {
    let navigate = useNavigate();
  const reintentar = () => {
    window.location.reload();
    //       console.log("asdasd");
        let path = `/`;
        navigate(path);
  };
  return (
    <div
      className="container d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div>
        <h3>Ha ocurrido algo inesperado</h3>
        <div className="justify-content-center text-center">
          <button style={{backgroundColor: "#2ca3ff",color: "#fff", border: "none", borderRadius: "8px", padding: "0.2rem 1rem", fontSize: "12px", fontWeight: "800",
  boxShadow: "0px 2px 5px 0px #8890a35c"}} onClick={() => reintentar()}>
            Reintentar
          </button>
        </div>
      </div>
    </div>
    //   <section className="container h-100">
    //     <div className="row h-100 justify-content-center align-items-center">
    //       <div className="col-12">
    //         <div className="d-flex align-items-center justify-content-center">
    //           <h3>Ha ocurrido algo inesperado</h3>
    //           <button className="btn-celeste">Reintentar</button>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
  );
}
export default Error;
