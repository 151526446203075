import http from "../http-common";
class CategoriaDatosServicio {
  listadoCategoria() {
    return http.get("/categoria/listar");
  }
  buscarCategoriaPorId(id) {
    // return http.get(`/tutorials/${id}`);
    return http.get(`/categoria/buscar/id/${id}`);
  }
  buscarCategoriaPorNombre(nombre) {
    return http.get(`/categoria/buscar/nombre/${nombre}`);
  }
  //   create(data) {
  //     return http.post("/tutorials", data);
  //   }
  //   update(id, data) {
  //     return http.put(`/tutorials/${id}`, data);
  //   }
  //   delete(id) {
  //     return http.delete(`/tutorials/${id}`);
  //   }
  //   deleteAll() {
  //     return http.delete(`/tutorials`);
  //   }
  //   findByTitle(title) {
  //     return http.get(`/tutorials?title=${title}`);
  //   }
}
export default new CategoriaDatosServicio();
