import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { obtenerCategorias } from '../actions/categoria';
import { obtenerProductos } from '../actions/producto';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CardProductoMaterial from './CardProductoMaterial';



class CategoriasCarruselSlick extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      empresaid: '',
      paisid: '',
      carruselContador: 0,
    };
    // if (!repos || repos.length === 0) return <p>No repos, sorry</p>;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.props.obtenerCategorias();
      this.props.obtenerProductos('s');
    }

  }
  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.empresa.length > 0 && nextProps.empresa[0] ){

  //         this.setState({
  //           empresaid: nextProps.empresa[0].id,
  //           paisid: nextProps.empresa[0].paisid,
  //         });
  //   }
  componentWillReceiveProps({ empresa }) {
    if (empresa) {
      this.setState({
        empresaid: empresa.id,
        paisid: empresa.paisid,
        colorprimario:empresa.colorprimario,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  
  
  render() {

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };


    const { categorias, productos } = this.props;
    if (categorias && productos) {
      return (
        /* CARUSEL CATEGORÍAS */
        <section className='d-none d-xl-block container-fluid container-xxl mt-xl-5'>
          {categorias &&
            categorias.map((categoria, index) => (
              <div key={index} className='mt-5'>
                <div className='row mt-5 mb-3'>
                  <div className='col-12'>
                    <h5
                      className='titulo titulo-xl'
                      style={{ color: categoria.color || this.state.colorprimario }} //ricardo Urbieta 21-10-2022 trae color de empresa si no tiene color la categoria
                    >
                      {categoria.nombre}
                    </h5>
                  </div>
                </div>
                <div className='row mt-2 mb-2 container'>


                  <Slider {...sliderSettings}>
                    {productos &&
                      productos.map((producto, productoIndex) => {
                        if (producto.categoria.id === categoria.id) {
                          return (
                            <CardProductoMaterial
                              producto={producto}
                              empresa={this.props.empresa}
                              key={productoIndex}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                  </Slider>
                </div>
              </div>
            ))}
        </section>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    categorias: state.categorias,
    empresa: state.empresa,
    productos: state.producto,
  };
};
// export default Categorias;
export default connect(mapStateToProps, {
  obtenerCategorias,
  obtenerProductos,
})(CategoriasCarruselSlick);
