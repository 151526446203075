import {
  OBTENER_PRODUCTOS,
  OBTENER_PRODUCTOS_POR_CATEGORIA,
  OBTENER_PRODUCTO_POR_ID,
  OBTENER_PRODUCTOS_AVANZADO,
} from "./types";
import ProductoDatosServicio from "../services/producto.service";
export const obtenerProductos = (destacado = 'n') => async (dispatch) => {
  try {
    const res = await ProductoDatosServicio.listadoProductos(destacado);
    dispatch({
      type: OBTENER_PRODUCTOS,
      payload: res.data.producto || null,
    });
  } catch (err) {
    console.log(err);
  }
};
export const obtenerProductosPorCategoria = (categoriaid) => async (dispatch) => {
    try {
      const res = await ProductoDatosServicio.buscarProductoPorCategoria(
        categoriaid
      );
      dispatch({
        type: OBTENER_PRODUCTOS_POR_CATEGORIA,
        payload: res.data.producto || null,
      });
    } catch (err) {
      console.log(err);
    }
  };
export const obtenerProductoPorId = (productoid) => async (dispatch) => {
  try {
    const res = await ProductoDatosServicio.buscarProductoPorId(productoid);
    dispatch({
      type: OBTENER_PRODUCTO_POR_ID,
      payload: res.data.producto || null,
    });
  } catch (err) {
    console.log(err);
  }
};
export const obtenerProductosBusquedaAvanzada = (datos) => async (dispatch) => {
  try {
    const res = await ProductoDatosServicio.buscarProductoAvanzado(datos);
    dispatch({
      type: OBTENER_PRODUCTOS_AVANZADO,
      payload: (res.data.producto && res.data.producto.length > 0 ? res.data.producto : null),
    });
  } catch (err) {
    console.log(err);
  }
};
