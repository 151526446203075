import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';

// import '../assets/css/estilo.css'
class Footer extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      empresaid: '',
      empresa: '',
      paisid: '',
    };
  }

  componentWillReceiveProps({ empresa }) {
    if (empresa) {
      this.setState({
        empresa: empresa,
        empresaid: empresa.id,
        paisid: empresa.paisid,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.empresa && this.state.empresa.sucursal) {
      return (
        <footer className='footer mt-5 py-4'>
          <section className='container-fluid container-xxl'>
            <div
              className='row align-items-center flex-sm-row flex-column-reverse'
              style={{ '--bs-gutter-x': 0 }}
            >
              <div className='logo justify-content-center text-center text-sm-start col-12 col-sm-8 mt-4 mt-sm-0'>
                <img
                  src={
                    process.env.REACT_APP_URL_IMAGENES +
                    this.state.empresa.paisid +
                    '/' +
                    this.state.empresa.id +
                    '/' +
                    this.state.empresa.logo
                  }
                  alt={this.state.empresa.alias}
                />
              </div>
              <div className='redes-sociales col-12 col-sm-4'>
                {this.state.empresa.urlfacebook && (
                  <a
                    href={this.state.empresa.urlfacebook}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-facebook-f' />
                  </a>
                )}
                {this.state.empresa.urlinstagram && (
                  <a
                    href={this.state.empresa.urlinstagram}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-instagram' />
                  </a>
                )}
                {this.state.empresa.urltwitter && (
                  <a
                    href={this.state.empresa.urltwitter}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-twitter'></i>
                  </a>
                )}

                {this.state.empresa.whatsapp && (
                  <a
                    href={
                      'https://api.whatsapp.com/send?phone=' +
                      this.state.empresa.whatsapp
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-whatsapp' />
                  </a>
                )}
              </div>
            </div>
          </section>
        </footer>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    empresa: state.empresa,
  };
};

export default connect(mapStateToProps)(Footer);

// export default Footer;
