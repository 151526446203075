import {
  OBTENER_PRODUCTOS,
  OBTENER_PRODUCTOS_POR_CATEGORIA,
  OBTENER_PRODUCTO_POR_ID,
  OBTENER_PRODUCTOS_AVANZADO,
} from "../actions/types";
const initialState = [];
function productoReducer(producto = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OBTENER_PRODUCTOS:
      return payload;
    case OBTENER_PRODUCTOS_POR_CATEGORIA:
      return payload;
    case OBTENER_PRODUCTO_POR_ID:
      return payload;
    case OBTENER_PRODUCTOS_AVANZADO:
      return payload;
    // case CREATE_TUTORIAL:
    //   return [...tutorials, payload];
    // case RETRIEVE_TUTORIALS:
    //   return payload;
    // case UPDATE_TUTORIAL:
    //   return tutorials.map((tutorial) => {
    //     if (tutorial.id === payload.id) {
    //       return {
    //         ...tutorial,
    //         ...payload,
    //       };
    //     } else {
    //       return tutorial;
    //     }
    //   });
    // case DELETE_TUTORIAL:
    //   return tutorials.filter(({ id }) => id !== payload.id);
    // case DELETE_ALL_TUTORIALS:
    //   return [];
    default:
      return producto;
  }
}
export default productoReducer;
