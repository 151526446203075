import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import { connect } from "react-redux";
import { obtenerEmpresaPorSubdominio } from "./actions/empresa";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Promocion from "./components/Promociones";
import Categorias from "./components/Categorias";
import CategoriasCarruselSlick from "./components/CategoriasCarruselSlick";
import Busqueda from "./components/Busqueda";
import Productos from "./components/Productos";
import ProductosCategoria from "./components/ProductosCategoria";
import Producto from "./components/Producto";
import ProductoFichaTecnica from "./components/ProductoFichaTecnica";
import Error from "./components/Error";
import './assets/css/estilo.css';
import GlobalStyles from "@mui/material/GlobalStyles";



require("dotenv").config();


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empresaid: "",
      paisid: "",
      categorias: "",
      
    };
  }
  componentDidMount() {
    this.props.obtenerEmpresaPorSubdominio();
  }

  componentWillReceiveProps({ empresa }) {
    // console.log('ACA VIENE LA EMPRESA')
    // console.log(empresa)
    
    if (empresa && empresa.sucursal) {

      if(empresa.plantilla === "plantilla_01"){
        this.state.plantillaProducto = <Producto/>;
      } else {
        this.state.plantillaProducto = <ProductoFichaTecnica/>;
      }

      // let tema =  {
        
      //   ".cardImgPrincipalProducto": {
      //     width: "1000px",
      //     height: "1000px",
      //   }
      // }
      // if (empresa.plantilla === "plantilla_01"){
        
      //   tema =  {
      //     ".cardImgPrincipalProducto": {
      //       width: "700px",
      //       height: "700px",
      //       borderRadius: "5px",
           
      //     }
      //   }
      // }

    

      this.setState({
        alias: empresa.alias,
        tienda: empresa.tienda,
        colorprimario:empresa.colorprimario,  //se agregó para probar cambiar color dinamicamente
        biografia: empresa.biografia,
        //temaEmpresa: tema

      });
      
    }
    

  }

 

  render() {
   
    {document.documentElement.style.setProperty('--color-celeste',this.state.colorprimario)}
    
  

    if (this.props.empresa === "0") {
      return (
        <React.Fragment>
          <Error />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
  
          {/* <GlobalStyles
            styles={this.state.temaEmpresa}
          /> esto es para aplicar los temas definidos en componentWillReceiveProps */}

            <Navbar alias={this.state.alias} />
            <main className="">
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      {/* <Busqueda /> */}
                      <Promocion />
                      <Categorias />
                      <CategoriasCarruselSlick />
                      {/* <Producto /> */}
                    </>
                  }
                />
                {/* producto por categoria */}
                <Route path="/categoria/:id" element={<Productos />} />
                <Route
                  path="/productocategoria/:id"
                  element={
                    <ProductosCategoria
                      colorEmpresa={this.state.colorprimario}
                    />
                  }
                />
                {/* <Route path="/productos" element={<Productos />} /> */}
                {/* <ThemeProvider  theme = {theme}> */}
                {/* <Route path="/producto/:id" element={<ProductoFichaTecnica />} />{" "} */}
                <Route path="/producto/:id" element={this.state.plantillaProducto} />
                {/* esto era para usar el HOC withStyles className={classes.myCustomClass} */}
                {/* </ThemeProvider> */}
                {/* <Route path="/busqueda/:nombre/:categoriaid?/:preciomin?/:preciomax?/:marcaid?" element={<Busqueda />} /> */}
                <Route path="/busqueda/*" element={<Busqueda />} />
                <Route path="/*" element={<Error />} />
              </Routes>
            </main>
            <Footer />
        </React.Fragment>
      );
    }
    // }
  }
}

// export default App;

const mapStateToProps = (state) => {
  return {
    empresa: state.empresa,
  };
};
// export default Categorias;
// export default withStyles(styles, { withTheme: true })(
//     withRouter(connect(mapStateToProps, {
//   obtenerEmpresaPorSubdominio,
// })(App)));

export default connect(mapStateToProps, {
  obtenerEmpresaPorSubdominio,
})(App);
// asi estaba originalmente


// export default withStyles(styles, { withTheme: true })(
//   withRouter(connect(mapStateToProps)(myComponent))
// )
// el ejemplo para usar el HOC withStyles