import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from '@material-ui/core/styles';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    // Grid,
    // Typography,
    // TablePagination,
    // TableFooter
 } from '@material-ui/core';

 
  
  const useStyles = makeStyles((theme) => ({
    table: {
      // minWidth: 650,
    },
    tableContainer: {
        borderRadius: 5,
        margin: '5px 0px',
        // maxWidth: 950
    },
    tableHeaderCell: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        backgroundColor: 'var(--color-celeste)', //theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark)
    },
    tableRow:{
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }
  }));

  export const CaracteristicasMaterialTable = ({producto}) => {
    const classes = useStyles();
          // console.log("ACA VIENE LA CARACTERISTICA");
          // console.log(producto.productocaracteristica)
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>Características</TableCell>
            <TableCell className={classes.tableHeaderCell}>Descripción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      
            {producto.productocaracteristica &&
              producto.productocaracteristica.map((item) => (
                  <TableRow key={item.id} className={classes.tableRow}>

                    <TableCell>
                      {item.caracteristica.nombre}
                    </TableCell>
                    <TableCell>
                    {item.caracteristicadetalle.valor}
                    </TableCell>
                    </TableRow>
              ))}
              </TableBody>
              </Table>
              </TableContainer>
  );
}
