import axios from "axios";
require("dotenv").config();
let host = window.location.host;
  // let protocol = window.location.protocol;
  let parts = host.split(".");
  let subdomain = "";
  let baseURL = process.env.REACT_APP_URL_API_MEGACOMMERCE;
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 2) {
    subdomain = parts[0];
    // baseURL = process.env.REACT_APP_HTTP+subdomain+".mgd.im:"+process.env.REACT_APP_PUERTO+"/api";
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
    // Set the location to the new url
    baseURL = process.env.REACT_APP_HTTP+"api.mgd.im"+process.env.REACT_APP_PUERTO+"/";
  }
export default axios.create({
//   baseURL: "http://megadigital.localhost:8000/api",
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
    "subdominio": subdomain
  },
});