import { OBTENER_MARCAS,
  OBTENER_MARCA_POR_ID, } from "./types";
import MarcaDatosServicio from "../services/marca.service";
export const obtenerMarcas = () => async (dispatch) => {
  try {
    const res = await MarcaDatosServicio.listadoMarca();
    dispatch({
      type: OBTENER_MARCAS,
      payload: res.data.marca,
    });
  } catch (err) {
    console.log(err);
  }
};
export const obtenerMarcaPorId = (marcaid) => async (dispatch) => {
  try {
    const res = await MarcaDatosServicio.buscarMarcaPorId(marcaid);
    console.log("res en marca por id ", res);
    dispatch({
      type: OBTENER_MARCA_POR_ID,
      payload: res.data.marca,
    });
  } catch (err) {
    console.log(err);
  }
};