// export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
// export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
// export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
// export const DELETE_TUTORIAL = "DELETE_TUTORIAL";
// export const DELETE_ALL_TUTORIALS = "DELETE_ALL_TUTORIALS";
//CATEGORIA
export const OBTENER_CATEGORIAS = "OBTENER_CATEGORIAS";
export const OBTENER_CATEGORIA_POR_ID = "OBTENER_CATEGORIA_POR_ID";
//EMPRESA
export const OBTENER_EMPRESAPORSUBDOMINIO = "OBTENER_EMPRESAPORSUBDOMINIO";
export const OBTENER_EMPRESA_ERROR = "OBTENER_EMPRESA_ERROR";
//PRODUCTO
export const OBTENER_PRODUCTOS = "OBTENER_PRODUCTOS";
export const OBTENER_PRODUCTOS_POR_CATEGORIA = "OBTENER_PRODUCTOS_POR_CATEGORIA";
export const OBTENER_PRODUCTO_POR_ID = "OBTENER_PRODUCTO_POR_ID";
export const OBTENER_PRODUCTOS_AVANZADO = "OBTENER_PRODUCTOS_AVANZADO";

//MARCA
export const OBTENER_MARCAS = "OBTENER_MARCAS";
export const OBTENER_MARCA_POR_ID = "OBTENER_MARCA_POR_ID";

